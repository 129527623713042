














































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver } from 'vee-validate';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import FormInput from '@/components/common/form-elements/FormInput.vue';

@Component({
  components: {
    FormInput,
    ValidationObserver,
  },
})
export default class Reset extends Vue {
  email: string = '';

  @Action sendPasswordResetEmail!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @StateClass passwordReset!: State['passwordReset'];

  @Ref('form') readonly form!: HTMLFormElement;

  @Watch('passwordReset', { deep: true })
  onPasswordResetChanged(newState: State['passwordReset'], oldState: State['passwordReset']): void {
    // Error
    if (newState && newState.status === DataContainerStatus.Error) {
      this.addToastMessage({
        text: newState.error,
        type: 'danger',
      });
    }

    // Success
    if (newState && newState.status === DataContainerStatus.Success) {
      // Reset form to initial state, and prevent
      // showing validation errors ('... is required')
      this.email = '';
      this.$nextTick(() => {
        this.form.reset();
      });

      this.addToastMessage({
        text: 'A password reset e-mail has been sent to your e-mail address.',
        type: 'success',
      });
    }
  }
}
