


























import { ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';

export enum FormIcons {
  Euro = 'form-control-euro',
  Year = 'form-control-years',
  Percentage = 'form-control-percentage',
  Duration = 'form-control-duration',
}

@Component({
  components: {
    FormInvalidMessage,
    ValidationProvider,
  },
  inheritAttrs: false,
})

export default class FormInput extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop() placeholder: string | undefined;
  @Prop() rules!: string | Record<string, any>;
  @Prop() label!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: 'aggressive' }) mode!: string;
  @Prop({ default: '' }) icon!: FormIcons;
  @Prop({ default: 'text' }) type!: string;
  @Prop({ default: true }) bails!: boolean;

  isNumberInput: boolean = false;
  inputMode: string | boolean = false;

  get inputType() {
    if (this.isNumberInput) {
      return 'text';
    }
    return this.type;
  }

  created() {
    if (this.type === 'number') {
      this.isNumberInput = true;
      if (this.rulesInclude('decimal')) {
        this.inputMode = 'decimal';
      }
      if (this.rulesInclude('integer')) {
        this.inputMode = 'numeric';
      }
    }
  }

  inputEvent(target) {
    let targetValue: string = target.value;
    if (this.isNumberInput) {
      targetValue = targetValue.replace(',', '.');
    }
    this.$emit('input', targetValue);
  }

  // Helper method to check if the given rules (which can be string or object) contain the given rule
  rulesInclude(rule: string): boolean {
    return (typeof this.rules === 'string' && this.rules.includes(rule))
      || (typeof this.rules === 'object' && rule in (this.rules as Record<string, any>));
  }
}
